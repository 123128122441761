const pastMasters = [
  {
    year: "1854",
    name: "Samuel H. Jones",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1855",
    name: "Samuel H. Jones",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1856",
    name: "Samuel H. Jones",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1857",
    name: "Samuel H. Jones",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1858",
    name: "Samuel H. Jones",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1859",
    name: "Nicholas Fenn",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1860",
    name: "John D. Mathews",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1861",
    name: "Nathaniel Gaskill",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1862",
    name: "Samuel H. Jones",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1863",
    name: "Nicholas Fenn",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1864",
    name: "Nicholas Fenn",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1865",
    name: "Samuel H. Jones",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1866",
    name: "Robert F. Graves",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1867",
    name: "Robert F. Graves",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1868",
    name: "Robert E. Bland",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1869",
    name: "Richard R. Gee",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1870",
    name: "Richard R. Gee",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1871",
    name: "William T. Harrison",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1872",
    name: "William T. Harrison",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1873",
    name: "Robert E. Bland",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1874",
    name: "Samuel H. Jones",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1875",
    name: "Richard R. Gee",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1876",
    name: "Robert E. Bland",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1877",
    name: "James J. Cook",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1878",
    name: "George McPhail Batte",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1879",
    name: "George McPhail Batte",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1880",
    name: "George McPhail Batte",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1881",
    name: "William D. Temple",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1882",
    name: "William D. Temple",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1883",
    name: "Robert E. Bland",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1884",
    name: "Robert E. Bland",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1885",
    name: "Josiah M. Jordon, Jr.",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1886",
    name: "Josiah M. Jordon, Jr.",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1887",
    name: "Theophilus M. Newell",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1888",
    name: "Theophilus M. Newell",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1889",
    name: "Samuel C. Gwaltney",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1890",
    name: "William H.C.Spicer",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1891",
    name: "William H.C.Spicer",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1892",
    name: "William H.C.Spicer",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1893",
    name: "Samuel C. Gwaltney",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1894",
    name: "William D. Temple",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1895",
    name: "William D. Temple",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1896",
    name: "Harry L. Smith",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1897",
    name: "Henry Wiseman",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1898",
    name: "George Potts",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1899",
    name: "George Potts",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1900",
    name: "Richard Epps",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1901",
    name: "Richard Epps",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1902",
    name: "William H.C.Spicer",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1903",
    name: "William H.C.Spicer",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1904",
    name: "William H.C.Spicer",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1905",
    name: "William H.C.Spicer",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1906",
    name: "George Potts",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1907",
    name: "George Potts",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1908",
    name: "Edmunds Gee",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1909",
    name: "Edmunds Gee",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1910",
    name: "John D. Webb",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1911",
    name: "John D. Webb",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1912",
    name: "John D. Webb",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1913",
    name: "John D. Webb",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1914",
    name: "John D. Webb",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1915",
    name: "John D. Webb",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1916",
    name: "Charles L. Wolfe",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1917",
    name: "John O. Faison",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1918",
    name: "Lewis W. Morris",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1919",
    name: "John J. Temple",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1920",
    name: "John J. Temple",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1921",
    name: "William C. Webb",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1922",
    name: "John D. Webb",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1923",
    name: "Lawrence W. Morris",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1924",
    name: "Harvey G. Glauss",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1925",
    name: "Clarence R. Dolby",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1926",
    name: "Alfred G. Livesay",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1927",
    name: "John J. Temple",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1928",
    name: "John J. Temple",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1929",
    name: "Jordon Wood",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1930",
    name: "Jordon Wood",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1931",
    name: "Jordon Wood",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1932",
    name: "Jordon Wood",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1933",
    name: "John C. Tomko",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1934",
    name: "William A. Walton",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1935",
    name: "William A. Walton",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1936",
    name: "Jordon Wood",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1937",
    name: "Jordon Wood",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1938",
    name: "Jordon Wood",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1939",
    name: "Jordon Wood",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1940",
    name: "J.A.Young",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1941",
    name: "J.A.Young",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1942",
    name: "J.A.Young",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1943",
    name: "J.A.Young",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1944",
    name: "W.A.Walton",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1945",
    name: "W.A.Walton",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1946",
    name: "Abraham Lavendorf",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1947",
    name: "Abraham Lavendorf",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1948",
    name: "Abraham Lavendorf",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1949",
    name: "Abraham Lavendorf",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1950",
    name: "Jordon E. Wood",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1951",
    name: "Jordon E. Wood",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1952",
    name: "Jordon E. Wood",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1953",
    name: "Jordon E. Wood",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1954",
    name: "Jordon E. Wood",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1955",
    name: "Jordon E. Wood",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1956",
    name: "Morris W. Clements",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1957",
    name: "Melvin W. Dickerson",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1958",
    name: "Joseph H. Martin",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1959",
    name: "Breece Jones",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1960",
    name: "Welford Carroll Morris",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1961",
    name: "George Waymack, Jr.",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1962",
    name: "George Waymack, Jr.",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1963",
    name: "George Waymack, Jr.",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1964",
    name: "Fred Simmons",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1965",
    name: "Fred Simmons",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1966",
    name: "Jack Williams",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1967",
    name: "Roy J. Bendall",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1968",
    name: "Harold E. Harrison",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1969",
    name: "Clyde E. May",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1970",
    name: "Jesse P. Elder",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1971",
    name: "John Atwood",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1972",
    name: "William F. Horne",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1973",
    name: "James W. Tomko",
    deceased: false,
    honorary: true,
    pastDDGM: true,
  },
  {
    year: "1974",
    name: "Vernon R. Brigman",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1975",
    name: "Kerry Dale Marks",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1976",
    name: "Charlie M. Taylor",
    deceased: false,
    honorary: true,
    pastDDGM: true,
  },
  {
    year: "1977",
    name: "Durelle E. Crymes, Jr.",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1978",
    name: "Richard Zuskin",
    deceased: true,
    honorary: true,
    pastDDGM: false,
  },
  {
    year: "1979",
    name: "Herbert L. Thompson",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1980",
    name: "Carl E. Pippin Sr.",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1981",
    name: "Leslie M. Brogdon",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1982",
    name: "Elbert V. Mabe, Jr.",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1983",
    name: "Irving J. Benesek, Jr.",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1984",
    name: "Edward S. Wilkerson",
    deceased: false,
    honorary: true,
    pastDDGM: true,
  },
  {
    year: "1985",
    name: "Charles D. Wade",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1986",
    name: "Richard P. Stanley",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1987",
    name: "Joseph H. Crouch",
    deceased: false,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1988",
    name: "Benny R. McDonald",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1989",
    name: "Fred H. Green",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1990",
    name: "Henry Sharber, III",
    deceased: false,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1991",
    name: "Boyd Griggs",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1992",
    name: "James Wilkerson",
    deceased: true,
    honorary: true,
    pastDDGM: false,
  },
  {
    year: "1993",
    name: "Gene Mims",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1994",
    name: "Keith Johnson",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1995",
    name: "Jimmy Hardin",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1996",
    name: "John L. Head",
    deceased: true,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "1997",
    name: "Larry Malcom",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1998",
    name: "Ricky Thompson",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "1999",
    name: "Searl Stover, Jr.",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2000",
    name: "Carl Carden, Jr.",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2001",
    name: "Vincent Carden",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2002",
    name: "Ewell T. Alderman, Jr.",
    deceased: true,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2003",
    name: "Emerson Sebra, Jr.",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2004",
    name: "Robin C. Sowers",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2005",
    name: "Charles McCracken",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2006",
    name: "Perry B. Williams",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2007",
    name: "Cecil M. Smith",
    deceased: false,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "2008",
    name: "E.Thomas Alderman, III",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2009",
    name: "Roy B. Pugh, III",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2010",
    name: "L.Joseph Hudson",
    deceased: false,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "2011",
    name: "Peter Michael Miller",
    deceased: false,
    honorary: false,
    pastDDGM: true,
  },
  {
    year: "2012",
    name: "J.Robert Loftis",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2013",
    name: "Jamie Lee Fly",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2014",
    name: "Joseph C. Waymack",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2015",
    name: "Joey B. Collins",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2016",
    name: "Aaron William Gill",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2017",
    name: "Thomas Erik Guess",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2018",
    name: "Roy B. Pugh, IV",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2019",
    name: "Jason D. McPeak",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2020",
    name: "Bryan Scott Hammel",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2021",
    name: "Drew Eugene McKinney",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2022",
    name: "Howard Olan McKinney",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2023",
    name: "Jerry L. Dew, Jr.",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
  {
    year: "2024",
    name: "William F. Coyner",
    deceased: false,
    honorary: false,
    pastDDGM: false,
  },
];
export default pastMasters;
