const officers = [
  { title: "Worshipful Master", name: "Donald Howard Rackley, Jr," },
  { title: "Senior Warden", name: "Larry Cecil Tate" },
  { title: "Junior Warden", name: "Jerry Lee Dew, Jr." },
  { title: "Treasurer", name: "Roy B. Pugh, IV" },
  { title: "Secretary", name: "Bryan Scott Hammel" },
  { title: "Senior Deacon", name: "Jason Daniel McPeak" },
  { title: "Junior Deacon", name: "Cecil McLane Smith" },
  { title: "Chaplain", name: "James W. Tomko" },
  { title: "Tiler", name: "L. Joseph Hudson" },
];
export default officers;
