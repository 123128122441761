import React from "react";

const East = () => {
  return (
    <>
      <div className="card">
        <div className="card-header">
          <p>From The East</p>
        </div>
        <div className="card-body">
          <p>Brethren,</p>

          <p>
            It is with great honor that I address you as Worshipful Master of
            Prince George Lodge No. 115 for the first time. I hope that each of
            you had a joyful and restful holiday season.
          </p>

          <p>
            As we embark on a new year, I invite you all to join us for our
            January Stated Communication, which will take place on Thursday,
            January 2nd. We will begin the evening with a delicious dinner
            served at 6:30 PM, followed by the Stated Communication at 7:30 PM.
          </p>

          <p>
            This year, I am excited to introduce a new element to our
            meetings—each will have a unique theme designed to guide our
            discussions and activities. The theme for this month is
            “Foundation.” January is the perfect time to focus on the
            fundamental principles that unite us as Masons and to lay the
            groundwork for a successful and fulfilling year ahead.
          </p>

          <p>
            At this meeting, we will discuss big plans for 2025, and I encourage
            every Brother to attend and share your input. Your voice and ideas
            are essential as we build upon the solid foundation of our Lodge and
            chart a course for the future.
          </p>

          <p>
            Let us begin the year with enthusiasm, collaboration, and a renewed
            commitment to the principles of Freemasonry. I look forward to
            seeing you all at the January meeting and working together to
            achieve our shared goals.
          </p>
        </div>
        <div className="card-footer">
          <p>Donald H. Rackley Jr.</p>
          <p>Worshipful Master, PG Lodge #115</p>
        </div>
      </div>
    </>
  );
};

export default East;
