import React from "react";

const Home = () => {
  return (
    <>
      <div style={{ marginTop: "50px" }} className="container-fluid">
        <p className="firstHead">Prince George Lodge No. 115 AF &amp; AM</p>
        <p className="secondHead">Stated Communication: 1st Thursday</p>
        <p className="secondHead">Meeting at 7:30 p.m.; Dinner at 6:30 p.m.</p>
        <img
          className="img-fluid"
          src="/images/lodge.jpg"
          alt="Lodge Building"
        />
        <div className="row">
          <div className="col-md-5 col-xs-8">
            <div>Prince George Lodge No. 115</div>
            <div>5709 Ruffin Road</div>
            <div>Prince George, VA 23875</div>
          </div>
          <div className="col-md-4 col-xs-4">
            <img
              className="img-fluid"
              style={{ marginTop: "10px" }}
              src="/images/150pin.jpg"
              alt="Prince George Lodge No. 115"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <h2>Next Stated</h2>
            <p>
              The January 2nd stated meeting will begin at 7:30 p.m. Dinner will
              start at 6:30.
            </p>
            <p>
              The menu for dinner: Jimmy's Famous Red Beans and Rice, Cornbread,
              and a surprise dessert.
            </p>
          </div>

          <div className="col-md-6">
            <h2>Upcoming Events</h2>
            <ul>
              <li>
                Wednesday, Jan. 8 and Jan. 22 at 6 pm - Solomon's Circle Ritual
                and Education at Prince George Lodge
              </li>
              <li>
                Saturday, Jan. 18 8 am - Division Leadership Conference at
                Richmond (ACCA Shrine Center, Richmond)
              </li>
              <li>Tuesday, Jan. 28 7 pm District Ritual School</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
